<div class="bg-color">
  <div style="height: calc(100vh - 90px)">
     <div style="background-image: url('assets/images/login-bg.png'); position: relative;
            height: calc(100vh - ( 50vh + 90px)); background-size: cover;">
        <div class="center-pane">
            <h4>Welcome Manikandan,</h4>
            <img src="assets/images/logo-black.svg" style="width: 200px;" class="mb-3 mt-3">
            <h6>Hub for requesting solutions and showcasing creative concepts, <br> a central point for sourcing answers and pitching ideas.</h6>
        </div>
     </div>
     <div style="height: 100%; padding: 30px">
        <div class="mx-auto" style="width: 30%;  background: white;
        padding: 25px; border-radius: 10px; box-shadow: 0 0 4px 0px #d8cdcd;">
        <div style="display: flex; align-items: center; cursor: pointer;" (click)="navigateTo('prospect-provider/problem-statement')">
            <div style="border-radius: 50%; background: aliceblue; padding: 15px;">
                <img src="assets/images/crowdsourcing.png" style="width: 30px;">
            </div>
            <div class="ml-2">
                <h6 class="m-0">Challenge Workbench </h6>
                <p class="m-0">Manage your list</p>
            </div>
            <mat-icon style="margin-left: auto;">arrow_right_alt</mat-icon>
        </div>
        <hr>
        <div style="display: flex; align-items: center; cursor: pointer;" (click)="navigateTo('prospect-provider/idea-pitchs')">
            <div style="border-radius: 50%; background: aliceblue; padding: 15px;">
                <img src="assets/images/ideapitch.png" style="width: 30px;">
            </div>
            <div class="ml-2">
                <h6 class="m-0">Ideapitch</h6>
                <p class="m-0">Manage your list</p>
            </div>
            <mat-icon style="margin-left: auto;">arrow_right_alt</mat-icon>
        </div>
     </div>
  </div>
</div>