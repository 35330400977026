<div class="container py-2">
  <div style="display: flex; margin-left: -10px; align-items: center">
    <mat-icon class="mr-2" (click)="goBack()">arrow_back</mat-icon>
    <app-bread-crumb></app-bread-crumb>
  </div>
</div>
<div
  class="container"
  style="min-height: calc(100vh - 90px); padding: 30px; background: white"
>
  <div>
    <div class="row">
      <div class="col-sm-6 trn-trc">
        <h6>Ideapitch Creation</h6>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12">
        <mat-form-field appearance="legacy" class="w-100 px-1">
          <mat-label>Ideapitch Title</mat-label>
          <input matInput />
        </mat-form-field>
        <mat-form-field appearance="legacy" class="w-100 px-1">
          <mat-label>Description</mat-label>
          <textarea matInput rows="10"></textarea>
        </mat-form-field>
        <app-tag></app-tag>
        <h6 class="mt-4">Attach File</h6>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field appearance="legacy" class="w-100 px-1">
              <mat-label>Attachment Name</mat-label>
              <input matInput />
            </mat-form-field>
          </div>
          <div
            class="col-sm-1"
            style="display: flex; justify-content: space-between"
          >
            <button mat-icon-button color="secondary">
              <mat-icon>upload</mat-icon>
            </button>
            <button mat-icon-button color="success">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <mat-accordion>
          <mat-expansion-panel
            [expanded]="true"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            style="border-radius: 20px"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Attachments </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="padding: 20px; overflow-x: scroll">
              <div
                class="d-flex flex-row flex-nowrap overflow-auto"
                style="padding: 10px 0"
              >
                <div
                  class="card card-block mx-2"
                  style="
                    min-width: 150px;
                    height: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px;
                  "
                  *ngFor="let x of [].constructor(10); let i = index"
                >
                  <img src="assets/images/excel.svg" style="width: 30px" />
                  <p class="m-0">Attachment {{ i + 1 }}</p>
                  <mat-icon color="remove">clear</mat-icon>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <h6 class="mt-4">Link</h6>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field appearance="legacy" class="w-100 px-1">
              <mat-label>Link URL</mat-label>
              <input matInput />
            </mat-form-field>
          </div>
          <div
            class="col-sm-1"
            style="display: flex; justify-content: space-between"
          >
            <button mat-icon-button color="success">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <mat-accordion>
          <mat-expansion-panel
            [expanded]="true"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            style="border-radius: 20px"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Links (6) </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: scroll">
              <div
                class="d-flex flex-row flex-nowrap overflow-auto"
                style="padding: 10px"
              >
                <div
                  class="card card-block mx-2"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    border-radius: 15px;
                    cursor: pointer;
                  "
                  *ngFor="let x of [].constructor(3); let i = index"
                >
                  <div class="d-flex align-items-center">
                    <mat-icon class="lk">link</mat-icon>
                    <p class="m-0 asw">{{ x }}</p>
                    <mat-icon color="remove">clear</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <button mat-button color="draft" aria-label="add" class="mr-1">
        Save as Draft
      </button>
      <button
        mat-button
        color="success"
        aria-label="add"
        class="ml-1"
        (click)="navigateTo('prospect-provider/idea-pitch-response')"
      >
        Submit Idea
      </button>
    </div>
  </div>
</div>
