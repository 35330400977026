import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

const ELEMENT_DATA: any[] = [
  {org: "Infosys", accelerator: 'Nascomm', industry: 'Healthcare', sector: 'Product', category: 'Data, AI/ML, Cloud Computing +2 More', 'tags': "sdfa"},
  {org: "Google", accelerator: 'Nascomm++', industry: 'Multi', sector: 'Service', category: 'Cloud Computing +2 More', 'tags': "sdfa"},
 
];  


@Component({
  selector: 'app-create-idea-pitch',
  templateUrl: './create-idea-pitch.component.html',
  styleUrls: ['./create-idea-pitch.component.scss']
})
export class CreateIdeaPitchComponent implements OnInit {
  okr: any;
  panelOpenState = false;
  panelOpenState1 = false;
  step: any = '1';
  
  alpha: any;
  displayedColumns: string[] = ['select', 'org', 'accelerator', 'industry', 'sector' ,'category', 'tags'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private router: Router, private location: Location) { }
  

  ngOnInit(): void {
    this.alpha = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    this.okr = [{
      "name": "O3 Contribute to positive business outcomes through",
      "color": this.getGMapRandomColor()
    },{
      "name": "O4 Bend Cost Curve: Shift 40% ($119M) of non-labor IT cash costs from fixed to variable by 2025, allowing our costs to flex up or down with business volume.",
      "color": this.getGMapRandomColor()
    },{
      "name": "O5 Highly engaged, diverse, agile and globally-balanced workforce",
      "color": this.getGMapRandomColor()
    },{
      "name": "O1 Retain intellectual property by reducing IT",
      "color": this.getGMapRandomColor()
    },{
      "name": "02 Retain Delta IT Family cohesion by managing attrition ",
      "color": this.getGMapRandomColor()
    },{
      "name": "O3 Contribute to positive business outcomes through",
      "color": this.getGMapRandomColor()
    }]
  }

  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 100%, 40%, 80%)';
  } 

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  next(){
    this.step = '2';
  }

  previous(){
    this.step = '1';
  }

  goBack() {
    this.location.back();
  }
}
