import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-provider-login',
  templateUrl: './provider-login.component.html',
  styleUrls: ['./provider-login.component.scss']
})
export class ProviderLoginComponent implements OnInit {
  step = 1;

  constructor(private router: Router) { }

  ngOnInit(): void {
   
  }

  login(s: any){
    this.step = s;
  }

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

}
