import { Component, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { map, startWith, take } from 'rxjs/operators';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-prospect-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProspectHeaderComponent implements OnInit {
  
    constructor(
    private msalAuthService: MsalService,
    private dialog: MatDialog,private renderer: Renderer2,
    private router: Router, private datepipe: DatePipe, public fb: FormBuilder, private activatedRoute: ActivatedRoute, 
    private spinner: NgxSpinnerService, private toastrService: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.activatedRoute.params.subscribe((res: any) => {
   
    })
  }

  ngOnInit() {
    
  }

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }
}
