import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-problem-statement-detail',
    templateUrl: './problem-statement-detail.component.html',
    styleUrls: ['./problem-statement-detail.component.scss']
})
export class ProblemStatementDetailComponent implements OnInit {
    statements: any;
    panelOpenState: boolean = true;

    constructor(private router: Router, private location: Location) { }

    ngOnInit(): void {

    }

    navigateTo(url: any) {
        this.router.navigateByUrl(url)
    }

    getGMapRandomColor() {
        return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
    }

    goBack(){
        this.location.back();
    }

}
