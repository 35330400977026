<div class="container py-2">
  <div style="display: flex; margin-left: -10px; align-items: center">
    <mat-icon class="mr-2" (click)="goBack()">arrow_back</mat-icon>
    <app-bread-crumb></app-bread-crumb>
  </div>
</div>
<div
  class="container"
  style="min-height: calc(100vh - 90px); padding: 30px; background: white"
>
  <h5>AI enable in subscription collections and billing !!</h5>
  <hr />
  <div [hidden]="step == '2'">
    <div class="row">
      <div class="col-sm-6 trn-trc">
        <h6>Statement Response</h6>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12">
        <mat-form-field appearance="legacy" class="w-100 px-1">
          <mat-label>Description</mat-label>
          <textarea matInput rows="10"></textarea>
        </mat-form-field>
        <app-tag></app-tag>
        <h6 class="mt-4">Attach File</h6>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field appearance="legacy" class="w-100 px-1">
              <mat-label>Attachment Name</mat-label>
              <input matInput />
            </mat-form-field>
          </div>
          <div
            class="col-sm-1"
            style="display: flex; justify-content: space-between"
          >
            <button mat-icon-button color="secondary">
              <mat-icon>upload</mat-icon>
            </button>
            <button mat-icon-button color="success">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <mat-accordion>
          <mat-expansion-panel
            [expanded]="true"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            style="border-radius: 20px"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Attachments </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="padding: 20px; overflow-x: scroll">
              <div
                class="d-flex flex-row flex-nowrap overflow-auto"
                style="padding: 10px 0"
              >
                <div
                  class="card card-block mx-2"
                  style="
                    min-width: 150px;
                    height: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px;
                  "
                  *ngFor="let x of [].constructor(10); let i = index"
                >
                  <img src="assets/images/excel.svg" style="width: 30px" />
                  <p class="m-0">Attachment {{ i + 1 }}</p>
                  <mat-icon color="remove">clear</mat-icon>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <h6 class="mt-4">Link</h6>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field appearance="legacy" class="w-100 px-1">
              <mat-label>Link URL</mat-label>
              <input matInput />
            </mat-form-field>
          </div>
          <div
            class="col-sm-1"
            style="display: flex; justify-content: space-between"
          >
            <button mat-icon-button color="success">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <mat-accordion>
          <mat-expansion-panel
            [expanded]="true"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            style="border-radius: 20px"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Links (6) </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: scroll">
              <div
                class="d-flex flex-row flex-nowrap overflow-auto"
                style="padding: 10px"
              >
                <div
                  class="card card-block mx-2"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    border-radius: 15px;
                    cursor: pointer;
                  "
                  *ngFor="let x of [].constructor(3); let i = index"
                >
                  <div class="d-flex align-items-center">
                    <mat-icon class="lk">link</mat-icon>
                    <p class="m-0 asw">{{ x }}</p>
                    <mat-icon color="remove">clear</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="text-center mt-4">
      <button mat-button color="draft" aria-label="add" class="mr-1">
        Save as Draft
      </button>
      <button
        mat-button
        color="success"
        aria-label="add"
        class="ml-1"
        (click)="navigateTo('prospect-provider/statement-provider-response')"
      >
        Submit Response
      </button>
    </div>
  </div>
  <div [hidden]="step == '1'">
    <div class="row">
      <div class="col-sm-12 trn-trc">
        <h5>Statement Creation - Prospect Provider Selection</h5>
      </div>
      <div class="col-sm-6 trn-trc-1">
        <!-- <button mat-mini-fab color="primary" aria-label="add" (click)="navigateTo('challenge-workbench/create-prospect-provider')">
                    <mat-icon>add</mat-icon>
                </button> -->
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-8">
        <div style="overflow-x: scroll">
          <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
        </div>
      </div>
      <div class="col-sm-4 trn-tc-2">
        <input type="text" placeholder="Search ..." class="form-control" />
      </div>
    </div>
    <div class="trn-trc">
      <span
        *ngFor="let i of alpha"
        style="text-transform: uppercase"
        class="letter"
        >{{ i }}</span
      >
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z1 mt-3 ptable"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="10%">
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="org">
        <th mat-header-cell *matHeaderCellDef>Org Name / Startup Name</th>
        <td mat-cell *matCellDef="let element">
          <span style="display: flex; align-items: center">
            <img src="assets/images/defimg.png" class="mr-2" />
            {{ element.org }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="accelerator">
        <th mat-header-cell *matHeaderCellDef>Accelerator</th>
        <td mat-cell *matCellDef="let element">{{ element.accelerator }}</td>
      </ng-container>

      <ng-container matColumnDef="industry">
        <th mat-header-cell *matHeaderCellDef>Industry</th>
        <td mat-cell *matCellDef="let element">{{ element.industry }}</td>
      </ng-container>
      <ng-container matColumnDef="sector">
        <th mat-header-cell *matHeaderCellDef>Sector</th>
        <td mat-cell *matCellDef="let element">{{ element.sector }}</td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let element">{{ element.category }}</td>
      </ng-container>
      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef>Tags</th>
        <td mat-cell *matCellDef="let element">
          <!-- <app-tag></app-tag>     -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="selection.toggle(row)"
      ></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page of users"
    ></mat-paginator>

    <div class="trn-trc-1 mt-4" style="justify-content: space-between">
      <div>
        <button mat-button color="pble" aria-label="add" (click)="previous()">
          Previous
        </button>
      </div>
      <div>
        <button mat-button color="draft" aria-label="add" class="mx-1">
          Save
        </button>
        <button mat-button color="success" aria-label="add">Submit</button>
      </div>
    </div>
  </div>
</div>
