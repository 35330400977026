import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-idea-pitch-response',
    templateUrl: './idea-pitch-response.component.html',
    styleUrls: ['./idea-pitch-response.component.scss']
})
export class IdeaPitchResponseComponent implements OnInit {
    statements: any;
    panelOpenState: boolean = true;
    panelOpenState1: boolean = true;
    panelOpenState2: boolean = true;

    constructor(private router: Router, private location: Location) { }

    ngOnInit(): void {

    }

    navigateTo(url: any) {
        this.router.navigateByUrl(url)
    }

    getGMapRandomColor() {
        return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
    }

    goBack(){
        this.location.back();
    }

}
