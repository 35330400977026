import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-problem-statements',
  templateUrl: './problem-statements.component.html',
  styleUrls: ['./problem-statements.component.scss']
})
export class ProblemStatementsComponent implements OnInit {
    statements: any;
    completedStatements: any;

  constructor(private router: Router) { }
  

  ngOnInit(): void {
    this.statements = [{
        "name": "AI enable in subscription collections Enhancing Food Delivery Efficiency for a Growing Restaurant Chain",
        "color": this.getGMapRandomColor(),
        "status": "New",
        "recepient": 10,
        "response": 4,
        "last_updated_on": "2023-04-20"
    },
    {
        "name": "Developing a Secure Mobile Banking App for Enhanced Customer Convenience",
        "color": this.getGMapRandomColor(),
        "status": "New",
        "recepient": 30,
        "response": 17,
        "last_updated_on": "2023-04-22"
    },{
        "name": "Optimizing Energy Consumption in Smart Homes to Reduce Utility Bills",
        "color": this.getGMapRandomColor(),
        "status": "Draft",
        "recepient": 3,
        "response": 0,
        "last_updated_on": "2023-04-23"
    },{
        "name": "Improving Student Retention Rates in an Online Education Platform",
        "color": this.getGMapRandomColor(),
        "status": "Submitted",
        "recepient": 8,
        "response": 5,
        "last_updated_on": "2023-04-29"
    },{
        "name": "Enhancing Supply Chain Visibility and Efficiency for a Global E-commerce Retailer",
        "color": this.getGMapRandomColor(),
        "status": "Submitted",
        "recepient": 18,
        "response": 5,
        "last_updated_on": "2023-04-30"
    }],
    this.completedStatements = [{
        "name": "AI enable in subscription collections Enhancing Food Delivery Efficiency for a Growing Restaurant Chain",
        "color": this.getGMapRandomColor(),
        "status": "Completed",
        "recepient": 10,
        "response": 4,
        "last_updated_on": "2023-04-20"
    },
    {
        "name": "Developing a Secure Mobile Banking App for Enhanced Customer Convenience",
        "color": this.getGMapRandomColor(),
        "status": "Completed",
        "recepient": 30,
        "response": 17,
        "last_updated_on": "2023-04-22"
    },{
        "name": "Optimizing Energy Consumption in Smart Homes to Reduce Utility Bills",
        "color": this.getGMapRandomColor(),
        "status": "Completed",
        "recepient": 3,
        "response": 0,
        "last_updated_on": "2023-04-23"
    }]
  }

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 50%, 25%, 90%)';
  } 

}
