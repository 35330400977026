<div class="bg-color">
  <div style="height: calc(100vh - 90px)">
     <div style="background-image: url('assets/images/login-bg.png'); position: relative;
            height: calc(100vh - ( 50vh + 90px)); background-size: cover;">
        <div class="center-pane">
            <img src="assets/images/logo-black.svg" style="width: 200px;" class="mb-3 mt-3">
            <h6>Hub for requesting solutions and showcasing creative concepts, <br> a central point for sourcing answers and pitching ideas.</h6>
        </div>
     </div>
     <div style="height: 100%; padding: 30px">
        <div class="mx-auto" style="width: 30%;  background: white;
        padding: 25px; border-radius: 10px; box-shadow: 0 0 4px 0px #d8cdcd;">
            <div *ngIf="step == 1">
                <h6 class="text-center mb-4">Login using your credentials</h6>
                <div class="form-group">
                    <input class="form-control" placeholder="Enter email ...">
                </div>
                <div class="text-center">
                    <button mat-button color="green" (click)="login(2)"> Send OTP </button>
                </div>
            </div>
            <div *ngIf="step == 2">
                <h6 class="text-center mb-4">Login using your credentials</h6>
                <div class="form-group">
                    <input class="form-control" placeholder="Enter email ...">
                </div>
                <div class="form-group">
                    <input class="form-control" placeholder="Enter OTP ...">
                </div>
                <div class="text-center">
                    <button mat-button color="resend" class="mr-1"> Resend </button>
                    <button mat-button color="green"  class="ml-1" (click)="navigateTo('prospect-provider/dashboard')"> Login </button>
                </div>
            </div>
        </div>
     </div>
  </div>
</div>