import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { ProviderLoginComponent } from './provider-login/provider-login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProblemStatementsComponent } from './problem-statements/problem-statements.component';
import { ProblemStatementDetailComponent } from '../prospect-provider-workbench/problem-statement-detail/problem-statement-detail.component';
import { CreateResponseComponent } from './create-response/create-response.component';
import { StatementProviderResponseComponent } from './statement-provider-response/statement-provider-response.component';
import { IdeaPitchComponent } from './idea-pitches/idea-pitches.component';
import { CreateIdeaPitchComponent } from './create-idea-pitch/create-idea-pitch.component';
import { IdeaPitchResponseComponent } from './idea-pitch-response/idea-pitch-response.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: { breadcrumb: "" },
    children: [
      {
        path: 'login',
        data: { breadcrumb: 'Login' },
        component: ProviderLoginComponent
      },
      {
        path: 'dashboard',
        data: { breadcrumb: 'Dashboard' },
        component: DashboardComponent
      },
      {
        path: 'problem-statement',
        data: { breadcrumb: 'Challenge Statement' },
        component: ProblemStatementsComponent
      },
      {
        path: 'problem-statement-detail',
        data: { breadcrumb: 'Challenge Statement' },
        component: ProblemStatementDetailComponent
      },
      {
        path: 'create-response',
        data: { breadcrumb: 'Challenge Statement Response' },
        component: CreateResponseComponent
      },
      {
        path: 'statement-provider-response',
        data: { breadcrumb: 'Statement Provider Response' },
        component: StatementProviderResponseComponent
      },
      {
        path: 'idea-pitchs',
        data: { breadcrumb: 'Ideapitch' },
        component: IdeaPitchComponent
      },
      {
        path: 'create-idea-pitch',
        data: { breadcrumb: 'Ideapitch' },
        component: CreateIdeaPitchComponent
      },
      {
        path: 'idea-pitch-response',
        data: { breadcrumb: 'Ideapitch' },
        component: IdeaPitchResponseComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProspectProviderRoutingModule { }
