<div class="container" style="min-height: calc(100vh - 90px); padding: 30px; background: white;">
    <div class="row">
        <div class="col-sm-6 trn-trc">
            <h5>Idea Pitch</h5>
        </div>
        <div class="col-sm-6 trn-trc-1">
            <button mat-button mat-flat-button color="primary" aria-label="add" class="bg-primary text-white ml-2" (click)="navigateTo('prospect-provider/create-idea-pitch')">
               <mat-icon>add</mat-icon> Create Idea Pitch
            </button>
        </div>
    </div>
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Active (6)">
            <div style="overflow-x: hidden;">
                <div class="row mt-2">
                    <div class="col-sm-8">
                        <div style="overflow-x: scroll">
                            <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
                        </div>
                    </div>
                    <div class="col-sm-4 trn-tc-2">
                        <input type="text" placeholder="Search ..." class="form-control">
                    </div>
                </div>
                <div class="tab-holder">
                    <div class="flex-container-1">
                        <div class="flex-item p-1" *ngFor="let x of statements;">
                            <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="navigateTo('prospect-provider/idea-pitch-response')">
                                <img src="assets/images/edit.png" width="20px" (click)="navigateTo('prospect-provider/create-idea-pitch'); $event.stopPropagation()" *ngIf="x.status == 'Draft'" style="position: absolute; top: 10px;  right: 10px;">
                                <h6 class="mb-0 trn-nm" style="width: 80%">{{x.name}}</h6>
                                <div style="padding: 10px; text-align: center; margin: 15px -15px;">
                                    <app-tag></app-tag>
                                </div>
                                <div class="st-col" [ngStyle]="{'background': x.status == 'New' ? '#d38128' : x.status == 'Draft' ? 'grey' : x.status == 'Submitted' ? 'green' : ''}">
                                    {{x.status}}</div>
                                <div class="row rcp" *ngIf="x.status != 'Draft'">
                                    <div class="col-sm-6">
                                        <h5>{{x.recepient}}</h5>
                                        <h6>Received</h6>
                                    </div>
                                    <div class="col-sm-6">
                                        <h5>{{x.response}}</h5>
                                        <h6>Responded</h6>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="dos">
                                <div class="text-center">
                                    <img src="/assets/images/date.png" width="15px" class="mr-1">
                                    <span style="font-size: 10px">{{x.last_updated_on | date:'d-MM-YYYY'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Submitted (3)">
            
        </mat-tab>
        <mat-tab label="Closed (3)">
            
        </mat-tab>
    </mat-tab-group>
</div>