<div class="container" style="min-height: calc(100vh - 90px); padding: 30px; background: white;">
    <div class="row">
        <div class="col-sm-6 trn-trc">
            <h5>Challenge Statements </h5>
        </div>
    </div>
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Active (6)">
            <div style="overflow-x: hidden;">
                <div class="row mt-2">
                    <div class="col-sm-8">
                        <div style="overflow-x: scroll">
                            <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
                        </div>
                    </div>
                    <div class="col-sm-4 trn-tc-2">
                        <input type="text" placeholder="Search ..." class="form-control">
                    </div>
                </div>
                <div class="tab-holder">
                    <div class="flex-container-1">
                        <div class="flex-item p-1" *ngFor="let x of statements;">
                            <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="navigateTo('prospect-provider/problem-statement-detail')">
                                <div class="st-col" [ngStyle]="{'background': x.status == 'New' ? '#d38128' : x.status == 'Draft' ? 'grey' : x.status == 'Submitted' ? 'green' : ''}">
                                    {{x.status}}</div>
                                <h6 class="mb-0 trn-nm" style="width: 80%">{{x.name}}</h6>
                                <div style="padding: 10px; background: #00000047; text-align: center; margin: 15px -15px;">
                                    <h6 class="m-0 mb-2" style="font-size: 11px; letter-spacing: 10px;">DEADLINE</h6>
                                    <h5 class="m-0" style="font-size: 14px; letter-spacing: 4px;">10 AUG, 2023</h5>
                                </div>
                               
                            </div>
                            <div class="dos">
                                <button mat-button color="response" (click)="navigateTo('prospect-provider/create-response')">
                                    <span *ngIf="x.status == 'New'"> Create Response </span>
                                    <span *ngIf="x.status == 'Draft'"> Submit Response </span>
                                    <span *ngIf="x.status == 'Submitted'"> View Response </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Submitted (3)">
            
        </mat-tab>
        <mat-tab label="Closed (3)">
            
        </mat-tab>
    </mat-tab-group>
</div>