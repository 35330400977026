<nav class="navbar navbar-expand-lg navbar-dark bg-lavendar py-0 justify-content-between fixed-top">
    <div class="w-100 d-flex nav-lg-holder " >
        <div class="navbar-brand mr-1">
            <span class="pr-2 d-inline-block">
                <a href="https://www.kairhos.com/" target="_blank">
                    <img src="assets/images/logo.svg" class="img-header cursor-pointer" width="100" />
                </a>
            </span>
            <span class="pl-2 border-left-nav d-inline-block">
                <a href="/" class="text-decoration-none">
                    <img src="assets/images/icon.png" class="keplerLogo" />
                </a>
            </span>
        </div>
    </div>
    <div class="d-flex align-items-centered d-none d-sm-none d-md-none d-lg-none d-xl-block display-xl-none" id="login">
        <ul class="navbar-nav d-flex align-items-center">
            <li class="nav-item">
                <a class="btn d-flex p-0" style="align-items: center;"><img src="assets/images/logout_trans.png" alt="logout" width="30" height="30" class="cursor-pointer fs-4"></a>
            </li>
        </ul>
        
    </div>
</nav>