<div class="container py-2">
    <div style="display: flex; margin-left: -10px; align-items: center"> 
        <mat-icon class="mr-2" (click)="goBack()">arrow_back</mat-icon>
        <app-bread-crumb></app-bread-crumb>
    </div>
</div>
<div class="container" style="min-height: calc(100vh - 90px); padding: 30px; background: white;">
    <h4>AI enable in subscription collections and billing !!</h4>
    <app-tag></app-tag>
    <p style="font-size: 14px; margin-top: 15px; text-align: justify;">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
        The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
        <br><br>
        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
        The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
    </p>
    <h6>Impact</h6>
    <p style="font-size: 14px; margin-top: 15px; text-align: justify;">AI can improve the overall customer experience by providing personalized billing options, suggesting relevant subscription plans, and offering self-service tools for managing accounts. This leads to greater customer satisfaction and loyalty.</p>
    <h6>Technology Stack Preferences</h6>
    <p style="font-size: 14px; margin-top: 15px; text-align: justify;">JAVA, Python, Angular</p>
    <h6>PoC Expectation</h6>
    <p style="font-size: 14px; margin-top: 15px; text-align: justify;">This may include cost savings, efficiency improvements, or enhanced performance.</p>
    <h6>Objective Keyresults</h6>
    <div style="padding: 20px"> 
        <div class="row mb-2" *ngFor="let x of [].constructor(3); let i = index" style="font-size: 14px; border-bottom: 1px solid grey; padding: 10px 0">
            <div class="col-sm-6">
                {{i+1}}. O3 Contribute to positive business outcomes through IT cash costs from fixed.
            </div>
            <div class="col-sm-6">
                <b>Comment O3 Contribute </b>
            </div>
        </div>
    </div>
    <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false" style="border-radius: 20px;">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Attachments
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="padding: 20px; overflow-x: scroll">
            <div class="d-flex flex-row flex-nowrap overflow-auto" style="padding: 10px 0">
                <div class="card card-block mx-2" style="min-width: 150px; height: 150px; display: flex; 
                align-items: center; justify-content: center; border-radius: 15px;" 
                *ngFor="let x of [].constructor(10); let i = index">
                    <img src="assets/images/excel.svg" style="width: 30px;">
                    <p class="m-0">Attachment {{i+1}} </p>
                </div>
            </div>          
        </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="text-center mt-4">
        <button mat-button color="response" (click)="navigateTo('prospect-provider/create-response')">Create Response</button>
    </div>
</div>